window.addEventListener('DOMContentLoaded', event => {



    try {
        document.querySelector('.modal-background .close').addEventListener('click', el => {
            let popup = document.getElementById('gdpr_modal')
            popup.classList.add('hide')
        })
    } catch (e) {}
    try {
        document.querySelector('.modal-background').addEventListener('click', el => {
            let popup = document.getElementById('gdpr_modal')
            if (el.target === popup) {
                popup.classList.add('hide')
            }
        })
    } catch (e) {}
    try {
        document.querySelector('.modal-background').addEventListener('click', el => {
            let popup = document.getElementById('gdpr_modal')
            console.log(popup, el.target)
            if (el.target === popup) {
                popup.classList.add('hide')
            }
        })
    } catch (e) {

    }
    try {
        document.querySelector('#gdpr-button').addEventListener('click', el => {
            let popup = document.getElementById('gdpr_modal')
            popup.classList.add('hide')
        })
    } catch (e) {

    }


    try {
        document.getElementById('gdpr_link').addEventListener('click', el => {
            let popup = document.getElementById('gdpr_modal')
            popup.classList.remove('hide')
        })
    } catch (error) {
        
    }
})


window.addEventListener('DOMContentLoaded', event => {

    const close = document.getElementById('gdpr_close')
    const gdprBox = document.getElementById('cookie_consent')
    const form = document.getElementById('gdpr_form')

    // hide the modal
    if (close) {
        close.addEventListener('click', ev => {
            if (gdprBox) {
                gdprBox.classList.add('hide')
            }
        })
    }

    // if the user submits the form, check if the analytics cookie is enabled and set a cookie automatically
    // also inform the server

    form.addEventListener('submit', ev => {
        ev.preventDefault()
        const analytics = document.getElementById('analytics_cookie')
        const analyticsValue = analytics.checked
        const postAction = ev.target.action
        const data = {
            analytics: 0
        }
        if (analyticsValue) {
            setCookie('analyticsCookie', "true", 60)
            data.analytics = 1
        } else {
            setCookie('analyticsCookie', "false", 7)
            
        }


        // post the request to the server
        const request = new XMLHttpRequest();
        request.open('POST', postAction, true);
        request.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
        request.setRequestHeader('X-CSRFToken', cookies['csrftoken'])
        request.send(JSON.stringify(data))
        request.onreadystatechange = () => {

            if(request.readyState === 4 && request.status === 200) {
                if (gdprBox) {
                    gdprBox.classList.add('hide')
                }

                document.getElementById('gdpr_error').classList.add('hide')
                document.getElementById('gdpr_notification').classList.remove('hide')
                document.getElementById('gdpr_notification').textContent = "Uspešno ste posodobili svoje nastavitve."
                document.getElementById('gdpr_button').classList.add('hide')
              } else if (request.status === 200){
                document.getElementById('gdpr_error').textContent = "Prišlo je do napake pri povezavi do serverja. Prosimo, poskusite kasneje."
                document.getElementById('gdpr_error').classList.remove('hide')
              }
            }
    })
})

// delete cookie function
// this is designed to delete or disable analytics cookies we currently use
// currently not in use since no info gets sent back
const deleteAnalyticsCookie = (cookies) => {
    if (cookies) {
        for (const key of Object.keys(cookies)) {
            if (key.toLowerCase().startsWith('_ga')) {
                setCookie(key, "", 0)
            } else if (key.toLowerCase().startsWith('_mf')){
                setCookie(key, "", 0)
            } else if (key.toLowerCase().startsWith('_hj')){
                setCookie(key, "", 0)
            }
        }
    }
}



// set cookie function
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// function to parse cookies so that we can get the csrf token
function parse_cookies() {
    let cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(function (c) {
            let m = c.trim().match(/(\w+)=(.*)/);
            if(m !== undefined) {
                cookies[m[1]] = decodeURIComponent(m[2]);
            }
        });
    }
    return cookies;
}
let cookies = parse_cookies();