const handbookArrow = document.getElementById('toggleHandbook')
const handBookContainer = document.getElementsByClassName('handbook-container')[0]
const handbookNavigation = document.getElementsByClassName('handbook_navigation')[0]



try {
    handbookNavigation.addEventListener('click', (event) => {
        handBookContainer.classList.toggle('handbook-container_hidden')
        handbookArrow.classList.toggle('arrow_rotate')
        document.cookie=`handbooks=${getCookie('handbooks') == 'true' ? false : true};max-age=${60*60*24*30};path=/`
    })
} catch (e) {
    console.log(e)
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return false;
}


window.setTimeout(popupToggle, 5000)


function popupToggle() {
    let popup = document.getElementById('popup_register')
    try {
        popup.classList.remove('hide')
        popup.addEventListener('click', el => {
            if (el.target == popup) {
                popup.classList.add('hide')
            }
        })
        document.querySelector('.popup_register .close').addEventListener('click', el=> {
            popup.classList.add('hide')
        })
    } catch (e) {

    }

}





/* event listener for leadgen form */
let leadgen = document.getElementById('leadgen_register')
try {
    leadgen.addEventListener('click', el => {
        console.log('click')
        let popup = document.getElementById('leadgen_modal')
        popup.classList.remove('hide')
        try {
            popup.classList.remove('hide')
            popup.addEventListener('click', el => {
                
                if (el.target == popup) {
                    popup.classList.add('hide')
                }
            })
            document.querySelector('#leadgen_modal .close').addEventListener('click', el=> {
                popup.classList.add('hide')
            })
        } catch (e) {
            
        }
    })
} catch (error) {
    
}

function leadgenToggle() {
    let popup = document.getElementById('modal-background')
    try {
        popup.classList.remove('hide')
        popup.addEventListener('click', el => {
            console.log(el.target, 'asdfasfd')
            if (el.target == popup) {
                popup.classList.add('hide')
            }
        })
        document.querySelector('.leadgen_modal .close').addEventListener('click', el=> {
            popup.classList.add('hide')
        })
    } catch (e) {
        
    }

}
