try {
    const textArr = [...document.getElementById('text').querySelectorAll('h2,h3,h4,h5')]
    let ul = document.createElement('ul')
    textArr.forEach(el => {
        let li = document.createElement('li')
        let a = document.createElement('a')
        a.setAttribute('href', '#'+ el.getAttribute('id'))
        a.textContent = el.textContent
        li.append(a)
        ul.append(li)
    })
    document.querySelector('.toc').append(ul)
} catch (e) {
    console.debug(e)
}

try {
    const tocContainer = document.getElementById('tocContainer')
    let offsetTop = tocContainer.getBoundingClientRect().top
    tocContainer.classList.toggle('fixed')
    window.addEventListener('scroll')
} catch (e) {
    console.debug(e)
}