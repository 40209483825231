
const leadgenForm = document.getElementById('leadgenForm')


if (leadgenForm) {
    leadgenForm.addEventListener('submit', event => {
        event.preventDefault()
        event.stopPropagation()
        let action = leadgenForm.action
        let errors = document.getElementById('leadgenErrors')
        
        let data = {
            first_name: document.getElementById('id_first_name').value,
            last_name: document.getElementById('id_last_name').value,
            email: document.getElementById('id_email').value,

        }
        if (document.getElementById('id_email').value.length > 0) {
            document.getElementById('questionLoader').style.display = 'block'
            document.getElementById('leadgenFormContainer').classList.add('hide')
            const request = new XMLHttpRequest()
            request.open('POST', action, true);
            request.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
            request.setRequestHeader('X-CSRFToken', cookies['csrftoken'])
            request.send(JSON.stringify(data))
            request.onreadystatechange = () => {
                document.querySelector('.modal-background .close').display = 'none'
                if(request.readyState === 4 && request.status === 201) {
                    document.getElementById('leadgenFormContainer').classList.add('hide')
                    document.getElementById('explanation').classList.remove('hide')
                    document.getElementById('questionLoader').style.display = 'none'
                    document.getElementById('questionLoader').style.display = 'none'
                    
                    window.setTimeout(hideLeadgen, 4000)
                    
                    console.log(request.body)
                  } else if (request.status === 422) {
                    
                    document.getElementById('leadgenFormContainer').classList.add('hide')
                    document.getElementById('error').classList.remove('hide')
                    document.getElementById('questionLoader').style.display = 'none'
                    window.setTimeout(hideLeadgen, 4000)
                  }
                

                
            }
        } else {
           errors.textContent = 'Polja ne smejo biti prazna!'
           errors.style.color = 'red'
           question.style.display = ''
           
        }
    })
}


function hideLeadgen() {
    let popup = document.getElementById('leadgen_modal')
    popup.classList.add('hide')
    document.getElementById('leadgenFormContainer').classList.remove('hide')
    document.getElementById('explanation').classList.add('hide')
    document.getElementById('error').classList.add('hide')
}

// function to parse cookies so that we can get the csrf token
function parse_cookies() {
    var cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(function (c) {
            var m = c.trim().match(/(\w+)=(.*)/);
            if(m !== undefined) {
                cookies[m[1]] = decodeURIComponent(m[2]);
            }
        });
    }
    return cookies;
}
var cookies = parse_cookies();


try {
    document.querySelector('.modal-background .close').addEventListener('click', el => {
        let popup = document.getElementById('leadgen_modal')
        popup.classList.add('hide')
    })
}
catch (e) {

}