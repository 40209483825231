const form = document.getElementById('userQuestionForm')


if (form) {
    form.addEventListener('submit', event => {
        event.preventDefault()
        event.stopPropagation()
        let action = form.action
        let errors = document.getElementById('questionErrors')
        let question = document.getElementById('id_question')
        let questionText = document.getElementsByClassName('question')[0]
        let data = {
            user: document.getElementById('id_user').value,
            question: document.getElementById('id_question').value,
            email: document.getElementById('id_email').value,

        }
        if (document.getElementById('id_question').value.length > 0) {
            document.getElementById('questionLoader').style.display = 'block'
            document.getElementById('questionFormContainer').style.display = 'none'
            const request = new XMLHttpRequest()
            request.open('POST', action, true);
            request.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
            request.setRequestHeader('X-CSRFToken', cookies['csrftoken'])
            request.send(JSON.stringify(data))
            request.onreadystatechange = () => {
                
                if(request.readyState === 4 && request.status === 201) {
                    document.getElementById('questionFormContainer').textContent = 'Vprašanje je bilo uspešno poslano'
                    document.getElementById('questionFormContainer').style.display = ''
                    document.getElementById('questionLoader').style.display = 'none'
                    console.log(request.body)
                  } else if (request.status === 422) {
                    document.getElementById('questionFormContainer').textContent = 'Prišlo je do napake. Poskusite ponovno kasneje.'
                    document.getElementById('questionFormContainer').style.display = ''
                    document.getElementById('questionLoader').style.display = 'none'
                  }
                

                
            }
        } else {
           errors.textContent = 'Vprašanje ne sme biti prazno!'
           errors.style.color = 'red'
           question.style.display = ''
           
        }
    })
}


// function to parse cookies so that we can get the csrf token
function parse_cookies() {
    var cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(function (c) {
            var m = c.trim().match(/(\w+)=(.*)/);
            if(m !== undefined) {
                cookies[m[1]] = decodeURIComponent(m[2]);
            }
        });
    }
    return cookies;
}
var cookies = parse_cookies();